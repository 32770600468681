<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add Near By
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
     <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <validation-observer ref="CountryPageFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Description" rules="">
                <quill-editor
                  ref="descriptionEditor"
                  v-model="CountryPageForm.description"
                  :options="getEditorOptions('descriptionEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Title" label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="Title" rules="required">
                <b-form-input id="blog-edit-title" v-model="CountryPageForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="slug" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="slug" rules="required">
                <b-form-input id="blog-edit-slug" v-model="CountryPageForm.slug" @input="removeSpace($event)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Excerpt" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="Excerpt" rules="required">
                <b-form-input id="blog-edit-Excerpt" v-model="CountryPageForm.excerpt" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Country" label-for="blog-edit-category" class="mb-2">
              <validation-provider #default="{ errors }" name="country" rules="required">
                <v-select
                  id="blog-edit-"
                  v-model="CountryPageForm.country_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="VisitorList"
                  :reduce="(val) => val.id"
                >
                  <template #no-options="{ search, searching, loading }">
                    No data ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="">
            <div class="border rounded p-2" style="margin:0px">
              <h4 class="mb-1">image</h4>
              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group label=" image" label-for="blog-edit-title" class="mb-2">
                      <validation-provider #default="{ errors }" name="Tab Image " rules="required">
                        <b-form-file
                          ref="refInputE2"
                          v-model="CountryPageForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Image Caption" label-for="blog-edit-title" class="mb-2">
                      <b-form-input id="alt-image" v-model="CountryPageForm.image_caption" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12" class="mt-5">
            <div class="border rounded p-2" style="margin:0px">
              <h4 class="mb-1">Card image</h4>
              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE3"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group label=" image" label-for="blog-edit-title" class="mb-2">
                      <validation-provider #default="{ errors }" name="Card Image " rules="required">
                        <b-form-file
                          ref="refInputE3"
                          v-model="CountryPageForm.card_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label=" faq Title" label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="faq" rules="">
                <b-form-input id="blog-edit-title" v-model="CountryPageForm.faq_title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="faq description" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Details" rules="">
                <quill-editor
                  ref="faqDescriptionEditor"
                  v-model="CountryPageForm.faq_description"
                  :options="getEditorOptions('faqDescriptionEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label=" Courses Title" label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="ourses" rules="required">
                <b-form-input id="blog-edit-title" v-model="CountryPageForm.courses_title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Courses description" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Details" rules="">
                <quill-editor
                  ref="coursesDescriptionEditor"
                  v-model="CountryPageForm.courses_description"
                  :options="getEditorOptions('coursesDescriptionEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label=" Diploma Title" label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="ourses" rules="required">
                <b-form-input id="blog-edit-title" v-model="CountryPageForm.long_courses_title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Diploma description" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Details" rules="">
                <quill-editor
                  ref="longCoursesDescriptionEditor"
                  v-model="CountryPageForm.long_courses_description"
                  :options="getEditorOptions('longCoursesDescriptionEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label=" Meta Title" label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="meta_title" rules="required">
                <b-form-input id="blog-edit-title" v-model="CountryPageForm.meta_title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Meta Description" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="In-Link Name" rules="required">
                <b-form-input id="blog-edit-slug" v-model="CountryPageForm.meta_description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group label=" Meta Keywords" label-for="blog-content" class="mb-2">
              <b-form-tags v-model="CountryPageForm.meta_keywords" />
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button v-if="isLoading" variant="primary" disabled class="mr-1">
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import vSelect from 'vue-select'
import 'quill/dist/quill.snow.css'
import store from '@/store'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    const { proxy } = getCurrentInstance()
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refInputE3 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const refPreviewE3 = ref(null)
    const VisitorList = ref([])
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const isLoading = ref(false)
    const form = ref({
      description: '',
      title: '',
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      keywords: '',
      image: '',
      image_caption: '',
      country_id: '',
      slug: '',
      excerpt: '',
      faq_title: '',
      faq_description: '',
      courses_title: '',
      courses_description: '',
      long_courses_title: '',
      long_courses_description: '',
    })
    store.dispatch('price/getVistior').then(response => {
      VisitorList.value = response.data.data
    })
    const CountryPageForm = reactive(form)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      refPreviewEl.value.src = base64
    })
    const { inputImageRendererTab } = useInputImageRenderer(refInputE2, base64 => {
      refPreviewE2.value.src = base64
    })
    const { inputImageRendererTab3 } = useInputImageRenderer(refInputE3, base64 => {
      refPreviewE3.value.src = base64
    })
    const CountryPageFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      CountryPageFormvalidate.value.validate().then(success => {
        if (success) {
          isLoading.value = true
          formData.append('description', CountryPageForm.description)
          formData.append('meta_title', CountryPageForm.meta_title)
          formData.append('title', CountryPageForm.title)
          formData.append('excerpt', CountryPageForm.excerpt)
          formData.append('slug', CountryPageForm.slug)
          formData.append('country_id', CountryPageForm.country_id)
          formData.append('card_image', CountryPageForm.card_image)
          formData.append('meta_description', CountryPageForm.meta_description)
          formData.append('image', CountryPageForm.image)
          formData.append(' meta_keywords', CountryPageForm.meta_keywords)
          formData.append('image_caption', CountryPageForm.image_caption)
          formData.append('faq_title', CountryPageForm.faq_title)
          formData.append('faq_description', CountryPageForm.faq_description)
          formData.append('courses_title', CountryPageForm.courses_title)
          formData.append('courses_description', CountryPageForm.courses_description)
          formData.append('long_courses_title', CountryPageForm.long_courses_title)
          formData.append('long_courses_description', CountryPageForm.long_courses_description)

          store
            .dispatch('setting/addCountryPage', formData)
            .then(response => {
              CountryPageForm.description = ''
              CountryPageForm.meta_title = ''
              CountryPageForm.title = ''
              CountryPageForm.excerpt = ''
              CountryPageForm.slug = ''
              CountryPageForm.country_id = ''
              CountryPageForm.meta_description = ''
              CountryPageForm.image = ''
              CountryPageForm.meta_keywords = ''
              CountryPageForm.image_caption = ''
              CountryPageForm.faq_title = ''
              CountryPageForm.faq_description = ''
              CountryPageForm.courses_title = ''
              CountryPageForm.courses_description = ''
              CountryPageForm.long_courses_title = ''
              CountryPageForm.long_courses_description = ''
              CountryPageFormvalidate.value.reset()
              isLoading.value = false
              Vue.swal({
                title: ' Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const removeSpace = target => {
      CountryPageForm.slug = target.toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/&/g, '')
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''""=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }

   const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


    const linkHandler = (editorRef) => {
      const range = proxy.$refs[editorRef].quill.getSelection()
      if (range) {
        const url = prompt('Enter the URL')
        if (url) {
          const target = confirm('Open in new tab?') ? '_blank' : '_self'
          const linkValue = `<a href="${url}" target="${target}">${proxy.$refs[editorRef].quill.getText(range)}</a>`
          proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
        }
      }
    }

    const getEditorOptions = (editorRef) => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
        
            ['bold', 'italic', 'underline', 'strike'],
            ['clean'],
            ['undo', 'redo'],
            ['paragraph'],

          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef),
          },
        },
      },
    })

    return {
      isLoading,
      refInputE3,
      refPreviewE3,
      removeSpace,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      inputImageRendererTab3,
      Tabimage,
      CountryPageForm,
      inputImageRenderer,
      CountryPageFormvalidate,
      VisitorList,
      form,
      save,
      required,
      email,
      getEditorOptions,
       imageHandler,
      insertImage,
      imageProperties,
      linkHandler,
    }
  },

  components: {
    BCard,
    vSelect,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
.quill-editor {
  margin-left: 1px;
  width: 100%;
}
</style>
